var breakingNewsSlider = new Swiper('.breakingNewsSlider', {
	disableOnInteraction: false,
	loop: true,
	speed: 1000,
	slidesPerView: 1,
	spaceBetween: 2,
	autoHeight:false,
	autoplay: {
		delay: 4000
	}
});
$(".breakingNewsSlider").hover(function() {
    breakingNewsSlider.autoplay.stop();
}, function() {
    breakingNewsSlider.autoplay.start();
});

var tpSlider = new Swiper('.tpSlider', {
    disableOnInteraction: false,
    speed: 1000,
    //direction: 'vertical',
	slidesPerView: 2,
    spaceBetween: 1,
    slidesPerGroup: 2,
    //slidesPerView: "auto",
    touchRatio: 0.2,
    autoHeight:false,
	navigation:true,
	navigation: {
		nextEl: '.tpSlider .hs-next',
		prevEl: '.tpSlider .hs-prev',
	},
	loop: $('.tpSlider .swiper-slide').length > 2 ? true : false,
    autoplay: {
        delay: 10000
    },
    watchSlidesVisibility: true,
    breakpoints: {
    576: {
		slidesPerView: 1,
	    spaceBetween: 0,
	    slidesPerGroup: 1,	    
		loop: $('.tpSlider .swiper-slide').length > 1 ? true : false,
	    autoHeight: true,
        autoplay: {
            delay: 5000
        },
        watchSlidesVisibility: true
    }
  }
});
$(".tpSlider").hover(function() {
    tpSlider.autoplay.stop();
}, function() {
    tpSlider.autoplay.start();
});

var ourtakeSlider = new Swiper('.ourtakeSlider', {
	disableOnInteraction: false,	
	loop: $('.ourtakeSlider .swiper-slide').length > 1 ? true : false,
	speed: 1000,
	slidesPerView: 1,
	spaceBetween: 2,
	autoplay: {
		delay: 3000
	},
	navigation:true,
	navigation: {
		nextEl: '.ourtakeSlider .hs-next',
		prevEl: '.ourtakeSlider .hs-prev',
	},
	breakpoints: {
	480: {
	  slidesPerView: 1,
	  spaceBetween: 1,
	  autoplay:{
		disableOnInteraction: false
	  }
	}
  }
});
$(".ourtakeSlider").hover(function() {
    ourtakeSlider.autoplay.stop();
}, function() {
    ourtakeSlider.autoplay.start();
});


var jobAlertSlider = new Swiper('.jobAlertSlider', {
	disableOnInteraction: false,
	loop: true,
	loopedSlides: 0,
	speed: 1000,
	//effect: 'fade',
	autoplay: {
		delay: 5000
	},
	fadeEffect: {
		crossFade: true
	},
	pagination: {
		el: '.sldot',
		clickable: true
	}
});
$(".jobAlertSlider").hover(function() {
    jobAlertSlider.autoplay.stop();
}, function() {
    jobAlertSlider.autoplay.start();
});


var innerPageLeftSlider = new Swiper('.innerPageLeftSlider', {
	disableOnInteraction: false,
	loop: true,
	loopedSlides: 0,
	speed: 1000,
	//effect: 'fade',
	autoplay: {
		delay: 5000
	},
	fadeEffect: {
		crossFade: true
	},
	pagination: {
		el: '.sldot',
		clickable: true
	}
});
$(".innerPageLeftSlider").hover(function() {
    innerPageLeftSlider.autoplay.stop();
}, function() {
    innerPageLeftSlider.autoplay.start();
});

var gallerSlider = new Swiper('.gallerSlider', {
	disableOnInteraction: false,
	loop: true,
    effect: 'fade',
    autoHeight: true,
	slidesPerView: 1,
	spaceBetween: 1,
	pagination: {
	    el: '.swiper-pagination',
	    type: 'fraction',
	},
	navigation: {
	    nextEl: '.swiper-button-next',
	    prevEl: '.swiper-button-prev',
	},
});
$(".gallerSlider").hover(function() {
    gallerSlider.autoplay.stop();
}, function() {
    gallerSlider.autoplay.start();
});


var videoSlider = new Swiper('.videoSlider', {
	disableOnInteraction: false,
	loop: false,
	speed: 1000,
	slidesPerView: 4,
	spaceBetween: 15,
	navigation:true,
	navigation: {
		nextEl: '.vhs-next',
		prevEl: '.vhs-prev',
	},
	breakpoints: {
	991: {
	  slidesPerView: 3,
	  spaceBetween: 10,
	  autoplay:{
		disableOnInteraction: false
	  }
	},
	700: {
	  slidesPerView: 2,
	  spaceBetween: 10,
	  autoplay:{
		disableOnInteraction: false
	  }
	},
	480: {
	  slidesPerView: 1,
	  spaceBetween: 1,
	  autoplay:{
		disableOnInteraction: false
	  }
	}
  }
});
$(".videoSlider").hover(function() {
    videoSlider.autoplay.stop();
}, function() {
    videoSlider.autoplay.start();
});

jQuery(document).ready(function($){
  // init controller
  var controller = new ScrollMagic.Controller();

  var headPin = new ScrollMagic.Scene({
	triggerHook: 0,
	triggerElement: "#headerpin"
  })
	.setPin("#headerpin")
	.setClassToggle("#headerpin", "header-active")
	.addTo(controller);

  controller.updateScene(headPin, true);



var pinDuration = ($(".bodyContentSection").height() - $("#leftPin").height());

  var leftPin = new ScrollMagic.Scene({
	triggerHook: 0,
	offset: -90,
  duration: pinDuration+"px",
	triggerElement: "#leftPin",
  })
	.setPin("#leftPin", {pushFollowers: false})
	.addTo(controller);

  controller.updateScene(leftPin, true);

function sizeAll() {
  var w = window.innerWidth;
  if ( w < 992) {
    leftPin.removePin(true);
  } else {
    leftPin.setPin("#leftPin");
  }
}
$(window).resize(sizeAll);
sizeAll();


$('.animfadeIn').each(function(){
	var animdur = $(this).data("animdur") || 1;
	var hook = $(this).data("hook") || 0.8;
   var myScene = new ScrollMagic.Scene({
   triggerElement: this,
   triggerHook: hook,
   reverse: false
   })
   .setTween(TweenMax.fromTo(this, animdur, {autoAlpha:0, y: 10},{autoAlpha:1, y: 0}))
   .addTo(controller);
  });




if($('.slideout-menu').hasClass('slideout-menu')){

var slideout = new Slideout({
   'panel': document.getElementById('panel'),
   'menu': document.getElementById('menumob'),
   'padding': 256,
   'tolerance': 70
});


var fixed = document.querySelector('.headermob');

slideout.on('translate', function(translated) {
  fixed.style.transform = 'translateX(' + translated + 'px)';
});


slideout.on('beforeopen', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(256px)';
  $('.hamburger').addClass('h-active');
});

slideout.on('beforeclose', function () {
  fixed.style.transition = 'transform 300ms ease';
  fixed.style.transform = 'translateX(0px)';
  $('.hamburger').removeClass('h-active');
});

slideout.on('open', function () {
  fixed.style.transition = '';
});

slideout.on('close', function () {
  fixed.style.transition = '';
});

}
//cb box hover effect
TweenMax.set($(".cb-text"), {autoAlpha:0, y:30});
//TweenMax.set($(".cb-layer"), {height:"auto"});
$(".card-box.has-text").hover(function() {
  TweenMax.to($(this).find(".cb-layer"), 0.8, {height: "100%"});
  TweenMax.to($(this).find(".cb-text"), 0.8, {autoAlpha: 1, y: 0});
}, function() {
  TweenMax.to($(this).find(".cb-layer"), 0.8, {height: "75px"});
  TweenMax.to($(this).find(".cb-text"), 0.8, {autoAlpha: 0, y: 30});
});




var cl = $(".main-nav .nav").children().clone(true);

$('#menumob').append(cl).wrapInner('<ul></ul>').find(".btn-primary").addClass('btn-primary-reverse');

  // mobile menu
  $('.hamburger').click(function (event) {
	$(this).toggleClass('h-active');
	slideout.toggle();

  });


	$(".mArrow").on('click', function(event) {
		console.log("HA HA HA..");
		var $fl = $(this);
		$(this).parent().siblings().find('.submenu').slideUp();
		$(this).parent().siblings().find('.mArrow').removeClass('icon-keyboard_arrow_up').addClass('icon-keyboard_arrow_down').text("");
		if($fl.hasClass('icon-keyboard_arrow_down')){
			$fl.removeClass('icon-keyboard_arrow_down').addClass('icon-keyboard_arrow_up').text("");
		}else{
			$fl.removeClass('icon-keyboard_arrow_up').addClass('icon-keyboard_arrow_down').text("");
		}
		$fl.next(".submenu").slideToggle();
	});


//--- VIDEO PLAYER --//
var player = new Plyr('.myplyr');


//--- GO TOP --//

$(".gotop").hide();
$(function () {
	$(window).scroll(function () {
	  if ($(this).scrollTop() > 200) {
		$('.gotop').fadeIn();
	  } else {
		$('.gotop').fadeOut();
	  }
	});

	// scroll body to 0px on click
	$('.gotop a').click(function () {
	  $('body,html').animate({
		scrollTop: 0
	  }, 800);
	  return false;
	});
  });

});


//--- HOME TABS --//
$(".tablinks").on("click", function(){
	$("#"+$(this).data('tab')).fadeIn(500).siblings().hide();
	$(this).addClass("active").parent().siblings().find("a").removeClass("active");
});

//--- HOMEPAGE GALLERY THUNB HOVER --//
$(function(){
    $('.galleryLink').hover(
        function(){
            $(this).next().fadeIn();
        },
        function(){
            $(this).next().hide();   
        }
    )   
})

/*---- SEARCH SCRIPT ---*/

$(".searchBtn").click(function() {
    $(".searchPanel").fadeIn();
    $("input:text:visible:first").focus();
});

$(document).click(function() {
    $(".searchPanel").fadeOut();
});

$('.searchBtn').click(function(e) {
	e.stopPropagation();
});

$('.searchPanel').click(function(e) {
	e.stopPropagation();
});

/*---- SEARCH SCRIPT ENDS ---*/




//--- # PAGE LINKING --//

$(document).ready(function(e){
	function abc() {
		return window.width;
	}

	var $element = '';

	if (window.location.hash)
		scrollToHash(window.location.hash);

	$(window).on('hashchange', function() {
		scrollToHash(this.location.hash);
	});

	function scrollToHash(hashVal) {
		if (abc()>1199)
			$element = $('#headerpin').height();
		else
			$element = $('.headermob').height();

		var $target = $(hashVal);
		$('html, body').stop().animate({
			'scrollTop': $target.offset().top-$element
			// 'scrollTop': $target.offset().top-50
		}, 500, 'swing', function () {
		});
	}
});